export const CONTENT_TYPES = {
  armedforces: 'armedforcespage',
  'militant organizations': 'militaryorganizationpage',
  news: 'newspage',
  reports: 'reportpage',
  weapons: 'weapondetailpage',
  'defense companies': 'fireportpage',
};

export const CUSTOM_SECTION_TYPE = 'custom_section';
export const IMAGE_SECTION_TYPE = 'images';
export const GROUP_CHRONOLOGY_SECTION_TYPE = 'group_chronology';
export const TABLE_TYPE = 'table';
export const SUBSECTION_TYPE = 'subsection';

// FIReport content types
export const RM_CONTRACTORS_TYPE = 'RM_contractors';
export const MANUFACTURE_CONTRACTORS_TYPE = 'manufacture_contractors';
export const STYLED_BLOCK_TYPE = 'styled_block';
export const EQUIPMENT_TYPES_TYPE = 'equipment_types';
export const IMAGE_MATRIX_TYPE = 'image_matrix';
export const IMAGE_TYPE = 'image';
export const PARAGRAPH_TYPE = 'paragraph';
export const SECTION_TYPE = 'section';

export const DEFENSE_COMPAMIES = 'Defense Companies';
