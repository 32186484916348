import * as Sentry from '@sentry/nextjs';

import { useRouter } from 'next/router';
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  API_VERSION,
  getRequest,
  NEXT_PUBLIC_API_URL,
} from '../../api/wagtail';
import { CONTENT_TYPES } from '../../consts/content-types';
import initDateRangeState from '../../consts/initDateRangeState';
import { NOT_COVERED_TITLE_PERCENTS } from '../../consts/style-consts';
import {
  DDNC_NEWS_URL,
  FULL_SEARCH_ROUTES,
  AUTOLOGIN_URL,
} from '../../consts/urls';
import { kebabToTitle } from '../../utils/caseconverters';
import { IS_NEXT_EXPORT } from '../../utils/export';
import { matchUrl } from '../../utils/matchUrl';
import removeDomainFromUrl from '../../utils/removeDomainFromUrl';
import removeQueryParameters from '../../utils/removeQueryParameters';

const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const router = useRouter();
  // Get the current full URL
  const currentHref = useMemo(() => {
    if (typeof window !== 'undefined') {
      // Runs only in the browser
      return window.location.href;
    }
    return ''; // Return an empty string or a default value for SSR
  }, [router.asPath]);
  const initialTag = matchUrl(router.asPath, [DDNC_NEWS_URL])
    ? 'news'
    : matchUrl(router.asPath, FULL_SEARCH_ROUTES)
    ? kebabToTitle(removeQueryParameters(router.asPath).split('/')[1])
    : null;
  // search
  const [isSearchActive, _setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchContentType, _setSearchContentType] = useState(
    CONTENT_TYPES[initialTag],
  );
  const [searchContentName, _setSearchContentName] = useState('');

  const [subcategoryFilterValue, setSubcategoryFilterValue] = useState(null);
  const [isSubcategoryFilterActive, setIsSubcategoryFilterActive] =
    useState(true);

  // header
  const [headerRef, setHeaderRef] = useState(null);
  const [percentOfCoveredTitle, setPercentOfCoveredTitle] = useState(1);

  // news and reports
  const [newsItems, setNewsItems] = useState([]);
  const [reportItems, setReportItems] = useState([]);
  const [dateRangeState, setDateRangeState] = useState([initDateRangeState]);
  const [isItemsLoading, setItemsLoading] = useState(false);

  // log-in
  const [user, setUser] = useState(null);
  const [isLoginFormActive, setIsLoginFormActive] = useState(false);

  const [isSubscribeRequestIsSent, setSubscribeRequestIsSent] = useState(false);

  // header
  const authButton = useRef(null);

  // navigation
  const [isNavActive, setIsNavActive] = useState(false);
  const [navItems, setNavItems] = useState(null);

  // gtm
  const [contentView, setContentView] = useState('notContent'); // "gated", "full" and "notContent"
  const setSearchContentType = (tag) => {
    _setSearchContentType(CONTENT_TYPES[tag]);
    _setSearchContentName(tag);
  };

  // cropped content
  const [isContentCropped, setIsContentCropped] = useState(false);

  const setIsSearchActive = (value) => {
    _setIsSearchActive(value);

    if (value) {
      setPercentOfCoveredTitle(NOT_COVERED_TITLE_PERCENTS);
    }
  };

  const tryToAutoLogin = async () => {
    try {
      const res = await getRequest(AUTOLOGIN_URL);

      const { json: { autologin: shouldLogin = false } = {} } = res || {};
      if (shouldLogin && authButton.current) {
        authButton.current.click();
      }
    } catch (e) {
      console.error('Error during auto-login:', e);
    }
  };

  useEffect(() => {
    try {
      const userJson = window.localStorage.getItem('user');
      const parsedData = userJson ? JSON.parse(userJson) : null;
      if (parsedData?.isLoggedIn) {
        setUser(parsedData);
      }
    } catch (error) {
      console.error('Error parsing user data:', error);
    }
  }, []);

  useEffect(() => {
    if (IS_NEXT_EXPORT) {
      return;
    }

    const retrieveAndSetUserProfile = async () => {
      window.dataLayer = window.dataLayer || [];

      try {
        const { json: fetchedUser } =
          (await getRequest(
            `${removeDomainFromUrl(
              NEXT_PUBLIC_API_URL,
            )}/${API_VERSION}/profile/`,
          )) || {};
        const {
          isLoggedIn,
          email,
          userLevel,
          userMetadata,
          userId,
          givenName,
        } = fetchedUser || {};
        if (isLoggedIn) {
          window.localStorage.setItem('user', JSON.stringify(fetchedUser));

          window.dataLayer.push({
            userId,
            email,
            subscriptionType: userLevel,
            contentView,
            userIsAuthenticated: true,
            subscriptionId: userMetadata?.subscriptionId,
            event: 'login',
            ...fetchedUser,
          });

          setUser(fetchedUser);

          Sentry.addBreadcrumb({
            category: 'auth',
            message: `Authenticated user ${email}`,
            level: 'info',
            data: fetchedUser,
          });

          Sentry.setUser({
            email,
            username: givenName,
            id: userId,
            userLevel,
            userMetadata,
          });
        } else {
          window.localStorage.removeItem('user');

          window.dataLayer.push({
            userId: 'anonymous',
            email: undefined,
            userIsAuthenticated: false,
            subscriptionId: undefined,
            event: 'logout',
          });
          setUser(null);
          tryToAutoLogin();
        }
      } catch (e) {
        console.error('Login error - ', e);
      }
    };

    retrieveAndSetUserProfile();
  }, []);

  const sharedState = {
    isSearchActive,
    setIsSearchActive,
    searchValue,
    setSearchValue,
    searchContentType,
    setSearchContentType,
    searchContentName,
    headerRef,
    setHeaderRef,
    percentOfCoveredTitle,
    setPercentOfCoveredTitle,
    newsItems,
    reportItems,
    setNewsItems,
    setReportItems,
    dateRangeState,
    setDateRangeState,
    isItemsLoading,
    setItemsLoading,
    user,
    setUser,
    isLogged: !!user?.isLoggedIn,
    isNavActive,
    setIsNavActive,
    isLoginFormActive,
    setIsLoginFormActive,
    navItems,
    setNavItems,
    subcategoryFilterValue,
    setSubcategoryFilterValue,
    isSubcategoryFilterActive,
    setIsSubcategoryFilterActive,
    contentView,
    setContentView,
    isSubscribeRequestIsSent,
    setSubscribeRequestIsSent,
    authButton,
    currentHref,
    isContentCropped,
    setIsContentCropped,
  };

  return (
    <GlobalContext.Provider value={sharedState}>
      {children}
    </GlobalContext.Provider>
  );
};

export function useGlobalContext() {
  return useContext(GlobalContext);
}
