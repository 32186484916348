const snakeToCamel = (s) => s.replace(/(_\w)/g, (x) => x[1].toUpperCase());

const ucFirst = (s) => s.charAt(0).toUpperCase() + s.slice(1);

const snakeToPascal = (s) => s.split('_').map(ucFirst).join('');
const camelToPascal = (s) => ucFirst(s);
const kebabToTitle = (s) => s.replace(/-/g, ' ');
const snakeToTitle = (s) => s?.replace(/_/g, ' ');
const camelToTitle = (s) =>
  s
    .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
    .replace(/^./, (match) => match.toUpperCase()) // Capitalize first letter
    .trim(); // Remove any leading spaces
/**
 * @link https://github.com/peet/camel-to-snake/blob/master/index.js
 */
const camelToSnake = (s) =>
  s
    .replace(
      /([a-z]|(?:[A-Z0-9]+))([A-Z0-9]|$)/g,
      (_, $1, $2) => $1 + ($2 && `_${$2}`),
    )
    .toLowerCase();

const convertObjectKeys = (
  obj,
  removeNull = true,
  converter = snakeToCamel,
) => {
  if (Array.isArray(obj)) {
    return obj.map((x) => convertObjectKeys(x, removeNull, converter));
  }

  if (typeof obj !== 'object') {
    return obj;
  }

  const r = {};
  for (const key in obj) {
    if (!(removeNull && obj[key] === null)) {
      r[converter(key)] =
        obj[key] && typeof obj[key] === 'object'
          ? convertObjectKeys(obj[key], removeNull, converter)
          : obj[key];
    }
  }

  return r;
};

const keysToSnakeFromCamel = (obj, removeNull = true) =>
  convertObjectKeys(obj, removeNull, camelToSnake);

const keysToCamelFromSnake = (obj, removeNull = true) =>
  convertObjectKeys(obj, removeNull);

export {
  ucFirst,
  convertObjectKeys,
  snakeToCamel,
  snakeToPascal,
  kebabToTitle,
  snakeToTitle,
  camelToPascal,
  camelToSnake,
  camelToTitle,
  keysToSnakeFromCamel,
  keysToCamelFromSnake,
};
