export const ARMED_FORCES_URL = '/armedforces';
export const MILITARY_ORGANIZATIONS_URL = '/militant-organizations';
export const WEAPONS_URL = '/weapons';
export const NEWS_LIST_URL = '/news';
export const REPORTS_LIST_URL = '/reports';
export const DDNC_NEWS_URL = '/ddnc';
export const DEFENSE_COMPAMIES_URL = '/defense-companies';

export const WHATS_NEW_URL = '/whats-new';
export const ABOUT_US_URL = '/about-us';
export const FAQ_URL = '/faq';
export const DIRECTORY_URL = '/directory';
export const POLICY_URL = 'https://www.govexec.com/about/privacy-policy/';
export const OUR_SIGHTS_URL = '/in-our-sights';
export const TERMS_URL = '/terms-conditions';
export const VIEW_DEMO_URL = '/tutorial';

export const CONTACT_US_URL = '/contact-us';
export const CONTACT_US_ENDPOINT = '/forms/contact-us/';
export const BECOME_MEMBER_ENDPOINT = '/forms/become-a-member/';
export const UPGRADE_BUTTON_URL = 'describe-your-proposal/';
export const UPGRADE_BUTTON_POST_ENDPOINT = '/forms/describe-your-proposal/';

export const FREE_TRIAL_URL = '/join';
export const FREE_TRIAL_ENDPOINT = '/auth/request-trial/';
export const FREE_TRIAL_COUNTRIES = '/auth/request-trial/country';

export const LOGIN_URL = '/login';
export const AUTOLOGIN_URL = '/accounts/autologin/';
export const TRIAL_UPGRADE_URL = '/auth/request-trial-upgrade/';

export const SEARCH_ENDPOINT = '/page_search/';
export const HOME_PAGE_URL = '/';

export const FILTERED_ROUTES = [REPORTS_LIST_URL, NEWS_LIST_URL, DDNC_NEWS_URL];
export const FULL_SEARCH_ROUTES = [
  MILITARY_ORGANIZATIONS_URL,
  WEAPONS_URL,
  ARMED_FORCES_URL,
  REPORTS_LIST_URL,
  NEWS_LIST_URL,
  DDNC_NEWS_URL,
  DEFENSE_COMPAMIES_URL,
];
